<template>
    <div :class="['annotation-stage']">
        <v-stage @click="handleClickStage" ref="annotation-stage" :config="configKonva">
            <v-layer ref="annotation-layer">
                <component
                    v-for="(object) in objects"
                    :is="getComponent(object.type)"
                    :key="object.id"
                    :object="object"
                    :isSelected="false"
                    v-bind="componentConfig"
                    :canResize="false"
                    @click="(e) => handleSelect(e, object)"
                    @click-label="(e) => handleSelectLabel(e, object)"
                    @object-updated="(e) => handleObjectUpdated(e)"
                    @dragend="(e) => handleDragEnd(e, object)"
                />

                <v-group ref="selection-group">
                    <component
                        v-for="(object) in selectedObjs"
                        :is="getComponent(object.type)"
                        :key="object.id"
                        :object="object"
                        :isSelected="mode === MODES.EDIT"
                        v-bind="componentConfig"
                        :canResize="canResize(object)"
                        @click="(e) => handleSelect(e, object)"
                        @click-label="(e) => handleSelectLabel(e, object)"
                        @object-updated="(e) => handleObjectUpdated(e)"
                        @dragend="(e) => handleDragEnd(e, object)"
                        @transformend="(e) => handleTransformEnd(e, object)"
                    />
                </v-group>
                <v-transformer
                    ref="transformer"
                    :config="{
                        enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
                        rotateEnabled: false,
                    }"
                />
            </v-layer>
        </v-stage>

        <!-- LabelDialog component -->
        <label-dialog
            :visible="showLabelDialog"
            :current-object="currentObject"
            @update-label="handleLabelUpdate"
            @close="handleDialogClose"
        />

        <!-- DurationDialog component -->
        <duration-dialog
            :visible="showDurationDialog"
            :frames="durationFrames"
            :minFrame="0"
            :maxFrame="totalVideoFrameCount"
            :object="durationObj"
            @update-range="updateRange"
            @close="this.$emit('close-duration-dialog')"
        />
    </div>
</template>

<script>
import RectObj from './VideoAnnotation/canvasObjects/RectObj.vue';
import LabelObj from './VideoAnnotation/canvasObjects/LabelObj.vue';
import CommentObj from './VideoAnnotation/canvasObjects/CommentObj.vue';
import LabelDialog from './VideoAnnotation/LabelDialog.vue';
import DurationDialog from './VideoAnnotation/DurationDialog.vue';
import { MODES, OBJ_TYPES, TOOLS } from './VideoAnnotation/VideoAnnotationConstants';

export default {
    components: {
        RectObj,
        LabelObj,
        CommentObj,
        LabelDialog,
        DurationDialog,
    },
    props: {
        mode: {
            type: String,
            required: true,
        },
        selectedTool: {
            type: Object,
            required: true,
        },
        width: {
            type: Number,
            required: true,
        },
        height: {
            type: Number,
            required: true,
        },
        scale: {
            type: Number,
            required: true,
        },
        objects: {
            type: Array,
            default: () => [],
        },
        selectedObjs: {
            type: Array,
            default: () => [],
        },
        selectFunctions: {
            type: Object,
            default: () => ({
                select: () => {},
                deselect: () => {},
                clear: () => {},
            }),
        },
        createObject: {
            type: Function,
            required: true,
        },
        currentFrame: {
            type: Number,
            default: 0,
        },
        durationObj: {
            type: Object,
            default: () => {},
        },
        durationFrames: {
            type: Array,
            default: () => [],
        },
        showDurationDialog: {
            type: Boolean,
            default: false,
        },
        totalVideoFrameCount: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        currentCursor: {
            handler(val) {
                this.stage.container().style.cursor = val;
            },
            immediate: true,
        },

        selectedTool: {
            handler(newVal) {
                if (newVal.value === TOOLS.RESIZE.value) {
                    this.handleObjectTransform();
                } else {
                    this.transformer.nodes([]);
                }
            },
        },
        width() {
            this.resizeStage();
        },
        height() {
            this.resizeStage();
        },

        selectedObjs: {
            handler() {
                if (this.selectedTool.value === TOOLS.RESIZE.value) {
                    this.handleObjectTransform();
                }
            },
            immediate: true,
        },
    },

    computed: {
        currentCursor() {
            switch (this.selectedTool.value) {
                case TOOLS.LABEL.value:
                case TOOLS.COMMENT.value:
                    return this.commentCursor;
                default:
                    return this.defaultCursor;
            }
        },

        configKonva() {
            return {
                width: this.width,
                height: this.height,
            };
        },

        isEditing() {
            return this.mode === MODES.EDIT;
        },

        componentConfig() {
            return {
                stage: this.stage,
                mode: this.mode,
                tool: this.selectedTool,
                scale: this.scale,
                boundDrag: this.boundDrag,
                defaultCursor: this.currentCursor,
            };
        },
    },

    data() {
        return {
            // Constants
            MODES,
            OBJ_TYPES,
            TOOLS,

            // Data
            stage: null,
            layer: null,
            transformer: null,

            // UI
            showLabelDialog: false,
            currentObject: null,
            // Cursor svgs
            commentCursor: `${'url'}("data:image/svg+xml,%3Csvg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Union'%3E%3Cmask id='path-1-inside-1_601_40' fill='white'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0.949997C0.895431 0.949997 0 1.84543 0 2.95V8.95C0 10.0546 0.89543 10.95 2 10.95H6.66159L8.56684 14.25C8.75929 14.5833 9.24042 14.5833 9.43287 14.25L11.3381 10.95H16C17.1046 10.95 18 10.0546 18 8.95V2.95C18 1.84543 17.1046 0.949997 16 0.949997H2Z'/%3E%3C/mask%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0.949997C0.895431 0.949997 0 1.84543 0 2.95V8.95C0 10.0546 0.89543 10.95 2 10.95H6.66159L8.56684 14.25C8.75929 14.5833 9.24042 14.5833 9.43287 14.25L11.3381 10.95H16C17.1046 10.95 18 10.0546 18 8.95V2.95C18 1.84543 17.1046 0.949997 16 0.949997H2Z' fill='white'/%3E%3Cpath d='M6.66159 10.95L7.0946 10.7L6.95026 10.45H6.66159V10.95ZM8.56684 14.25L8.13383 14.5L8.13383 14.5L8.56684 14.25ZM9.43287 14.25L9.86588 14.5L9.86588 14.5L9.43287 14.25ZM11.3381 10.95V10.45H11.0494L10.9051 10.7L11.3381 10.95ZM0.5 2.95C0.5 2.12157 1.17157 1.45 2 1.45V0.449997C0.619288 0.449997 -0.5 1.56928 -0.5 2.95H0.5ZM0.5 8.95V2.95H-0.5V8.95H0.5ZM2 10.45C1.17157 10.45 0.5 9.77842 0.5 8.95H-0.5C-0.5 10.3307 0.619288 11.45 2 11.45V10.45ZM6.66159 10.45H2V11.45H6.66159V10.45ZM8.99985 14L7.0946 10.7L6.22857 11.2L8.13383 14.5L8.99985 14ZM8.99986 14H8.99985L8.13383 14.5C8.51873 15.1667 9.48098 15.1667 9.86588 14.5L8.99986 14ZM10.9051 10.7L8.99986 14L9.86588 14.5L11.7711 11.2L10.9051 10.7ZM16 10.45H11.3381V11.45H16V10.45ZM17.5 8.95C17.5 9.77842 16.8284 10.45 16 10.45V11.45C17.3807 11.45 18.5 10.3307 18.5 8.95H17.5ZM17.5 2.95V8.95H18.5V2.95H17.5ZM16 1.45C16.8284 1.45 17.5 2.12157 17.5 2.95H18.5C18.5 1.56929 17.3807 0.449997 16 0.449997V1.45ZM2 1.45H16V0.449997H2V1.45Z' fill='black' mask='url(%23path-1-inside-1_601_40)'/%3E%3C/g%3E%3C/svg%3E%0A") 9 15, pointer`,
            moveCursor: 'move',
            defaultCursor: 'default',
        };
    },

    mounted() {
        // Get reference to layer
        this.layer = this.$refs['annotation-layer'].getNode();
        this.stage = this.$refs['annotation-stage'].getNode();
        this.transformer = this.$refs.transformer.getNode();

        // Setting up document event listener for clicks outside of video annotaiton
        document.addEventListener('click', this.handleDocumentClick);
    },

    onUnmounted() {
        document.removeEventListener('click', this.handleDocumentClick);
    },

    methods: {
        boundDrag(pos, { height, width }) {
            // Get stage dimensions
            const stage = this.$refs['annotation-stage'].getStage();
            const stageWidth = stage.width();
            const stageHeight = stage.height();

            // Calculate bounds
            let { x, y } = pos;

            // Constrain x position
            x = Math.max(0, x); // Left boundary
            x = Math.min(stageWidth - width, x); // Right boundary

            // Constrain y position
            y = Math.max(0, y); // Top boundary
            y = Math.min(stageHeight - height, y); // Bottom boundary

            return {
                x,
                y,
            };
        },

        printState() {
        },
        canResize(object) {
            if (this.selectedTool.value !== TOOLS.RESIZE.value && this.mode !== MODES.EDIT) {
                return false;
            }

            switch (object.type) {
                case OBJ_TYPES.RECT:
                    return true;
                case OBJ_TYPES.LABEL:
                    return false;
                default:
                    return false;
            }
        },

        resizeStage() {
            if (this.stage) {
                this.stage.size({
                    width: this.width,
                    height: this.height,
                });
                this.stage.batchDraw();
            }
        },

        handleSelect(e, obj) {
            const isSelected = this.selectedObjs.includes(obj);
            if (this.mode === MODES.EDIT) {
                switch (this.selectedTool.value) {
                    case TOOLS.LABEL.value:
                        this.handleShowLabelDialog(obj);
                        break;
                    default:
                        if (isSelected) {
                            this.selectFunctions.deselect(obj);

                            // TOOD: Maybe change this so that we watch or pass in node so we don't have to search for node.
                            this.handleObjectTransform();
                            return;
                        }

                        this.selectFunctions.select(obj);
                        break;
                }
            }
        },

        handleTransformEnd(event, obj) {
            const objUpdates = obj; // Shallow copy
            const node = event.target;
            const { x, y, scaleX, scaleY, width, height } = node.attrs;

            objUpdates.x = x / this.scale;
            objUpdates.y = y / this.scale;
            objUpdates.w = width * scaleX;
            objUpdates.h = height * scaleY;

            // Restting scales
            node.scaleX(1);
            node.scaleY(1);

            this.$emit('object-updated', objUpdates);
        },

        // eslint-disable-next-line no-unused-vars
        handleDragStart(event) { },

        handleDragEnd(event, obj) {
            const objUpdates = obj; // Shallow copy
            const { x, y } = event.target.attrs;

            objUpdates.x = x * this.scale;
            objUpdates.y = y * this.scale;

            this.$emit('object-updated', objUpdates);
        },

        // eslint-disable-next-line no-unused-vars
        handleDragMove(event) { },

        getComponent(type) {
            switch (type) {
                case OBJ_TYPES.RECT:
                    return RectObj;
                case OBJ_TYPES.LABEL:
                    return LabelObj;
                case OBJ_TYPES.COMMENT:
                    return CommentObj;
                default:
                    return null;
            }
        },

        handleObjectTransform() {
            this.$nextTick(() => {
                if (this.$refs.transformer && this.selectedObjs.length > 0) {
                    const transformer = this.$refs.transformer.getNode();
                    const { stage } = this;
                    const selectedNodes = stage.find(
                        (node) => this.selectedObjs.some((obj) => obj.x === node.x() && obj.y === node.y()),
                    );
                    transformer.nodes(selectedNodes);
                }
            });
        },

        getCommentCords(x, y, maxX, maxY, commentWidth) {
            const BORDER = 100;
            const COMMENT_HEIGHT = 50;
            const OFFSET = 100; // Padding from click position

            let commentX = x + OFFSET;
            let commentY = y;

            if (commentX > maxX - BORDER) {
                commentX = x - OFFSET - (commentWidth * this.scale);
            }

            if (y + COMMENT_HEIGHT > maxY - BORDER) {
                commentY = y - OFFSET;
            }

            return [commentX, commentY];
        },

        handleClickStage(e) {
            const { target, evt } = e;
            const { layerX, layerY } = evt;
            const x = layerX * this.scale;
            const y = layerY * this.scale;
            const maxX = this.width;
            const maxY = this.height;

            // Check if we clicked on the stage itself
            if (target === target.getStage()) {
                if (this.mode === MODES.EDIT) {
                    // TODO: Create case for label tool that places a label object on canvas
                    let commentX;
                    let commentY;
                    switch (this.selectedTool.value) {
                        case TOOLS.RECT.value:
                            this.createObject(OBJ_TYPES.RECT, x, y);
                            break;
                        case TOOLS.LABEL.value:
                            this.createObject(OBJ_TYPES.LABEL, x, y);
                            break;
                        case TOOLS.COMMENT.value:
                            [commentX, commentY] = this.getCommentCords(x, y, maxX, maxY, 250);
                            this.createObject(OBJ_TYPES.COMMENT, x, y, {
                                commentX,
                                commentY,
                            });
                            break;
                        default:
                            this.selectFunctions.clear();
                            break;
                    }
                }
            }
        },

        // Is specific to click label on rect object
        // eslint-disable-next-line no-unused-vars
        handleSelectLabel(e, obj) { },

        handleShowLabelDialog(obj) {
            this.showLabelDialog = true;
            this.currentObject = obj;
        },

        handleLabelUpdate(object, newLabel) {
            const updatedObject = object;

            updatedObject.label = newLabel;

            this.$emit('object-updated', updatedObject);
            this.handleDialogClose();
        },

        handleDialogClose() {
            this.showLabelDialog = false;
        },

        handleDirectionChange(label, direction) {
            const labelCopy = label; // Shallow copy
            labelCopy.pointerDirection = direction;
            this.$emit('object-updated', labelCopy);
        },

        handleObjectUpdated(obj) {
            const copy = obj; // Shallow copy

            this.$emit('object-updated', copy);
        },

        updateRange(object, range) {
            this.$emit('update-range', object, range);
        },

        handleDocumentClick(e) {
            const { target } = e;
            const annotationStage = this.$el;
            const toolBarRef = document.querySelector('#annotation-toolbar');

            if (!annotationStage || !toolBarRef) {
                return;
            }

            if (!annotationStage.contains(target) && !toolBarRef.contains(target)) {
                this.selectFunctions.clear();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.annotation-stage{
    position: absolute;
    pointer-events: auto;
    z-index: 999;

    // Enable pointer events for the Konva stage while keeping the wrapper non-interactive
    canvas {
        pointer-events: auto;
    }
}
</style>
