<template>
    <div v-if="visible" class="label-dialog">
        <div class="dialog-content">
            <h3>Update Label</h3>
            <input v-model="newLabel" placeholder="Enter new label" />
            <div class="dialog-actions">
                <button type="button" @click="updateLabel">Update</button>
                <button type="button" @click="closeDialog">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        currentObject: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            newLabel: this.getLabel(this.currentObject),
        };
    },
    methods: {
        updateLabel() {
            if (this.newLabel.trim().length === 0) {
                this.newLabel = null;
            }

            this.$emit('update-label', this.currentObject, this.newLabel);
            this.closeDialog();
        },
        closeDialog() {
            this.$emit('close');
        },

        getLabel(obj) {
            return obj?.label || '';
        },
    },
    watch: {
        currentObject(newVal) {
            this.newLabel = this.getLabel(newVal);
        },
    },
};
</script>

<style scoped>
.label-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dialog-actions {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

button {
    padding: 5px 10px;
    cursor: pointer;
}
</style>
