/**
 * Video Annotation Mixin
 *
 * @author: @Max Somerville
 *
 * @description: This mixin provides the data and methods for the video annotation component.
 *
 */

import { uniqueId } from 'lodash';
import axios from 'axios';
import {
    DEFAULT_DATA,
    OBJ_TYPES,
    DEFAULT_RECT,
    DEFAULT_LABEL,
    DEFAULT_COMMENT,
} from '@/components/local/data/VideoAnnotation/VideoAnnotationConstants';
import { getApiUrl } from '@/utils/api';
import noticesMixin from '@/mixins/noticesMixin';

export default {
    mixins: [noticesMixin],

    data() {
        return {
            ...DEFAULT_DATA,

            // UI
            showDurationDialog: false,
            durationObj: null,
            durationFrames: [],
            isSaving: false,

            annotationHash: null,
        };
    },

    computed: {
        selectedFunctions() {
            return {
                select: this.selectObj,
                deselect: this.deselectObj,
                clear: this.clearSelectedObjs,
            };
        },
    },

    methods: {
        async setTracks(tracks) {
            this.annotationTracks = { ...tracks };
            this.annotationHash = await this.createAnnotationHash(this.annotationTracks);
        },

        async createAnnotationHash(tracks) {
            const str = JSON.stringify(Object.entries(tracks).sort());
            const msgBuffer = new TextEncoder().encode(str);

            const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

            return hashHex;
        },

        setTool(tool) {
            this.selectedTool = tool;
        },

        setMode(mode) {
            this.mode = mode;
        },

        // Selection methods
        selectObj(obj) {
            // TODO: validation, maybe have a class and use instanceof to check if valid.

            // Only select one object at a time
            const indexOfObj = this.canvasObjs.findIndex((o) => o.id === obj.id);
            if (indexOfObj >= 0) {
                // Removing the object from canvas
                // Adding selected back to canvas
                // Adding object to selected
                this.canvasObjs.splice(indexOfObj, 1);
                this.canvasObjs.push(...this.selectedObjs);
                this.selectedObjs = [obj];
            } else {
                this.selectedObjs = [];
                this.canvasObjs.push(obj);
            }
        },

        deselectObj(obj) {
            this.selectedObjs = this.selectedObjs.filter((o) => o !== obj);
            this.canvasObjs.push(obj);
        },

        clearSelectedObjs() {
            this.canvasObjs.push(...this.selectedObjs);
            this.selectedObjs = [];
        },

        handleObjectUpdated(obj) {
            const indexOfObj = this.canvasObjs.findIndex((o) => o.id === obj.id);
            if (indexOfObj !== -1) {
                this.canvasObjs.splice(indexOfObj, 1, { ...obj });
            }

            const indexOfSelectedObj = this.selectedObjs.findIndex((o) => o.id === obj.id);
            if (indexOfSelectedObj !== -1) {
                this.selectedObjs.splice(indexOfSelectedObj, 1, { ...obj });
            }
        },

        /**
         * This is because we don't have UUID package.
         */
        generateUniqueId(prefix = 'obj_') {
            const existingIds = this.existingIds();

            let newId;
            do {
                newId = uniqueId(prefix);
                // OR if using UUID:
                // newId = uuidv4();
            } while (existingIds.has(newId));

            return newId;
        },

        existingIds() {
            const idSet = new Set();
            this.canvasObjs.map((obj) => idSet.add(obj.id));
            return idSet;
        },

        createObject(objType, x, y, options = { }) {
            const id = this.generateUniqueId();

            const {
                commentX = DEFAULT_COMMENT.comment.x,
                commentY = DEFAULT_COMMENT.comment.y,
            } = options;

            let createdObj;
            switch (objType) {
                case OBJ_TYPES.RECT:
                    createdObj = { ...DEFAULT_RECT, id, x, y };
                    this.canvasObjs.push(createdObj); // Should the created object be selected by default?
                    break;
                case OBJ_TYPES.LABEL:
                    createdObj = { ...DEFAULT_LABEL, id, x, y };
                    this.canvasObjs.push(createdObj);
                    break;
                case OBJ_TYPES.COMMENT:
                    createdObj = {
                        ...DEFAULT_COMMENT,
                        comment: {
                            ...DEFAULT_COMMENT.comment,
                            x: commentX,
                            y: commentY,
                        },
                        id,
                        x,
                        y,
                    };
                    this.canvasObjs.push(createdObj); // Comment field is required or field isn't deep copied

                    this.selectObj(createdObj);
                    break;
                default:
                    return;
            }
            // Adding to the frames
            this.objFrames[id] = [this.currentFrame];
        },

        deleteSelectedObjs() {
            this.deletedObjs.push(...this.selectedObjs);
            this.selectedObjs = [];
        },

        /**
         * Updates the canvasObjs to be objs of current frame. Using track data.
         * @param {Number} frameNum - The frame number to update.
         */
        updateAnnotationFrame(frameNum) {
            this.currentFrame = frameNum;

            // PERFORMANCE - If need performance, sorted binary search could help.
            const tracksWithFrame = Object.values(this.annotationTracks)
                .filter((track) => track.frames.includes(frameNum));

            this.canvasObjs = tracksWithFrame.map((track) => track.data);
            this.selectedObjs = [];
        },

        handleSaveAnnotationFrame() {
            if (this.canvasObjs.length === 0 && this.selectedObjs.length === 0 && this.deletedObjs.length === 0) {
                return;
            }

            // Updating / creating tracks
            const objs = [...this.canvasObjs, ...this.selectedObjs];
            objs.forEach((obj) => {
                let track = this.annotationTracks[obj.id];

                if (track) {
                    if (!track.frames.includes(this.currentFrame)) {
                        track.frames.push(this.currentFrame).sort((a, b) => a - b);
                    }
                } else {
                    track = {
                        id: obj.id,
                        type: obj.type,
                        frames: [this.currentFrame],
                        data: obj,
                    };
                }

                this.annotationTracks[obj.id] = track;
            });

            // Remove tracks of deleted objects
            this.deletedObjs.forEach((obj) => {
                delete this.annotationTracks[obj.id];
            });
        },

        handleChangeDuration() {
            const object = this.selectedObjs[0]; // TODO: handle multiple objects
            const track = this.objFrames[object.id];

            this.showDurationDialog = true;
            this.durationObj = object;
            this.durationFrames = track;
        },

        // eslint-disable-next-line no-unused-vars
        handleDurationUpdate(object, range) {
            // TODO: Implement for future if we want annotaitons to be on multiple frames.
            throw new Error('Not implemented');
        },

        handleDurationDialogClose() {
            this.showDurationDialog = false;
            this.durationObj = null;
            this.durationFrames = [];
        },

        async saveVideoAnnotations(videoId) {
            try {
                this.isSaving = true;
                const saveHash = await this.createAnnotationHash(this.annotationTracks);
                if (saveHash === this.annotationHash) {
                    this.isSaving = false;
                    return;
                }

                const url = getApiUrl({ path: `alpr/save/annotations/${videoId}/` });

                const response = await axios.put(url, {
                    annotations: this.annotationTracks,
                });

                if (response.status === 200) {
                    this.displaySuccessNotice({ message: 'Annotations saved' });
                }
            } catch (err) {
                console.error(err);
                this.displayErrorNotice({ message: 'Failed to save annotations' });
            }
            this.isSaving = false;
        },
    },
};
