<template>
    <div class="app-item">
        <div class="card is-rounded app-item__card">
            <button type="button" class="app-item__card__button" :class="buttonClasses" :style="getStyles(item)" @click.prevent="gotoApp()">
                <div v-if="item.icon" class="app-item__card__icon">
                    <b-icon :icon="item.icon" size="is-large" />
                </div>
                <div v-if="item.logo" class="app-item__card__logo">
                    <img :src="getLogoUrl(item.logo)" :alt="`${item.label} logo`">
                </div>
                <div class="app-item__card__content">
                    <p class="app-item__card__label">
                        {{ item.label }}
                    </p>
                    <p v-if="item.description" class="app-item__card__description">
                        {{ item.description }}
                    </p>
                </div>
                <span v-if="item.native" class="app-item__card__rvision">
                    <img src="@/assets/icons/rivions_logo_small_white.png" alt="R/Vision Logo">
                </span>
                <span v-if="isEnabled" class="app-item__card__installed">
                    <b-icon icon="check-circle" />
                </span>
            </button>
        </div>
        <div class="app-item__status">
            <p>
                <template v-if="item.priceLabel === 'Free'">
                    <span v-if="isInstalled">Installed ({{ item.priceLabel }})</span>
                    <span v-else>{{ item.priceLabel }}</span>
                </template>
                <template v-else>
                    {{ item.priceLabel }}
                </template>
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isInstalled: false,
        };
    },

    computed: {
        ...mapGetters('marketplace/user', ['allEnabledApps']),

        isEnabled() {
            return this.item.enabled || this.allEnabledApps.includes(this.item.slug);
        },

        isDisabled() {
            return this.item.disabled || false;
        },

        buttonClasses() {
            const classes = [];

            if (this.isEnabled) {
                classes.push('is-enabled');
            }

            if (this.isDisabled) {
                classes.push('is-disabled');
            }

            return classes;
        },

        url() {
            if (this.item.url) {
                return this.item.url;
            }

            return `/dashboard/marketplace/apps/${this.item.slug}/settings`;
        },
    },

    methods: {
        gotoApp() {
            if (this.url) {
                this.$router.push(this.url);
            }
        },

        getLogoUrl(logo) {
            const images = require.context('@/assets/logos', false, /\.png$/);
            try {
                const img = images('./' + logo + ".png"); // eslint-disable-line
                return img;
            } catch (err) {
                return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPMrAcAAVcA6iSCoPkAAAAASUVORK5CYII=';
            }
        },

        getStyles(item) {
            if (!item.bg) {
                return '';
            }
            return {
                backgroundColor: item.bg,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.app-item {
    display: flex;
    flex-direction: column;
    &__card {
        overflow: hidden;
        position: relative;
        flex: 1;
        display: flex;

        &__label {
            font-weight: $weight-bold;
        }

        &__description {
            margin-top: .25em;
            opacity: .7;
            font-size: .9em;
            line-height: 1.1;
        }

        &__button {
            @include button-reset;
            flex: 1;
            min-height: 160px;
            padding: 2rem 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            text-align: center;
            color: $text;
            background: lighten($dark, 3);
            font-size: 16px;
            border: 3px solid transparent;
            @include transition-button;

            &.is-enabled {
                border-color: $green;
                background: lighten($dark, 5);
            }

            &.is-disabled {
                cursor: not-allowed;
            }
            &:not(.is-disabled):hover {
                background: lighten($dark, 5);
            }
        }

        &__icon {
            margin-bottom: 1em;
        }

        &__installed {
            position: absolute;
            right: 10px;
            top: 10px;
            color: $green;
        }

        &__content {
            margin-top: auto;
            flex: 0 0 auto;
        }

        &__logo {
            flex: 0 0 52px;
            width: 52px;
            height: 52px;
            //padding: 9px;
            //background: $white;
            margin: 0 auto 1em;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $radius;

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }

        &__rvision {
            position: absolute;
            width: 20px;
            height: auto;
            left: 15px;
            bottom: 15px;

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }

    &__status {
        margin-top: 10px;
        text-align: center;
        line-height: 1.25;
    }
}
</style>
