/**
 * Video Annotation Constants
 *
 */

// DUMMY DATA FOR TESTING

/*
const DUMMY_RECT = {
    id: '1',
    type: 'rect',
    x: 100,
    y: 100,
    w: 100,
    h: 100,
    label: 'test-1',
    fillColor: null,
    borderColor: 'orange',
};

const DUMMY_RECT_2 = {
    id: '2',
    type: 'rect',
    x: 300,
    y: 300,
    w: 100,
    h: 100,
    label: 'test-2',
    fillColor: 'red',
    borderColor: 'blue',
};
*/

/*
const DUMMY_LABEL = {
    id: 'label-1',
    type: 'label',
    x: 400,
    y: 200,
    label: 'test-1',
    pointerDirection: 'down',
};
*/

export const DEFAULT_COMMENT = {
    id: null,
    type: 'comment',
    x: 100,
    y: 100,
    w: 1,
    h: 1,
    label: 'comment',
    comment: {
        width: 100,
        x: 200,
        y: 200,
        text: null, // Default is null so that comopnent know when new comment is created
    },
};

export const DEFAULT_RECT = {
    id: null,
    type: 'rect',
    x: 100,
    y: 100,
    w: 100,
    h: 100,
    label: null,
    fillColor: null,
    borderColor: 'black',
};

export const DEFAULT_LABEL = {
    id: null,
    type: 'label',
    x: 100,
    y: 100,
    label: null,
    pointerDirection: 'down',
};

export const OBJ_TYPES = {
    RECT: 'rect',
    LABEL: 'label',
    COMMENT: 'comment',
};

export const TOOLS = {
    SELECT: {
        value: 0,
        description: 'Select objects on canvas',
        name: 'select',
    },
    DELETE: {
        value: 1,
        description: 'Delete selected object from canvas',
        name: 'delete',
    },
    LABEL: {
        value: 2,
        description: 'Add labels to objects on canvas',
        name: 'label',
    },
    RECT: {
        value: 3,
        description: 'Add rectangles to objects on canvas',
        name: 'rect',
    },
    RESIZE: {
        value: 4,
        description: 'Resize selected object',
        name: 'resize',
    },
    COMMENT: {
        value: 5,
        description: 'Drop comments on video',
        name: 'comment',
    },
};

export const MODES = {
    VIEW: 'view',
    EDIT: 'edit',
};

export const DEFAULT_CANVAS_OBJS = [];

/*
export const DEFAULT_TRACK_DATA = {
    1: {
        id: '1',
        type: OBJ_TYPES.RECT,
        frames: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        data: DUMMY_RECT,
    },
    2: {
        id: '2',
        type: OBJ_TYPES.RECT,
        frames: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        data: DUMMY_RECT_2,
    },
};
*/

export const DEFAULT_DATA = {
    mode: MODES.VIEW,
    selectedTool: TOOLS.SELECT,
    canEdit: false, // Enables / diesabled edit mode
    canvasObjs: DEFAULT_CANVAS_OBJS,
    selectedObjs: [],
    deletedObjs: [],
    objFrames: {
    },
    annotationTracks: { },
    currentFrame: 0,
};

/*
 * Dummy Tracks
{
    1: {
        id: '1',
        type: 'rect',
        frames: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        data: {
            id: '1',
            type: 'rect',
            x: 100,
            y: 100,
            w: 100,
            h: 100,
            label: 'test-1',
            fillColor: null,
            borderColor: 'orange',
        },
    },
    2: {
        id: '2',
        type: 'rect',
        frames: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        data: {
            id: '2',
            type: 'rect',
            x: 300,
            y: 100,
            w: 100,
            h: 100,
            label: 'test-2',
            fillColor: null,
            borderColor: 'orange',
        },
    },
}
*/
