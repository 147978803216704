<template>
    <v-group>
        <v-label
            ref="label"
            :config="labelConfig"
            @click="$emit('click', $event)"
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
            @dragmove="handleDragMove"
            @transformend="$emit('transformend', $event)"
            @mouseenter="handleMouseEnter"
            @mouseleave="handleMouseLeave"

        >
            <v-tag
                :config="{
                    /*
                    pointerDirection: 'down',
                    pointerWidth: 10,
                    pointerLength: 10,
                    fill: 'white',
                    stroke: this.isSelected ? 'blue' : 'black',
                    strokeWidth: 1,
                    cornerRadius: 3,
                    padding: 5,
                    */
                    fill: 'white',
                    stroke: this.isSelected ? 'blue' : 'grey',
                    pointerDirection: this.pointerDirection,
                    pointerWidth: 10,
                    pointerHeight: this.pointerHeight,
                    lineJoin: 'round',
                }"
            />
            <v-text
                :config="{
                    text: object.label,
                    fontSize: 16,
                    padding: 5,
                    fill: 'black',
                }"
            />
        </v-label>

        <!-- Direction Control Points (shown when selected) -->
        <v-group v-if="isSelected && showDirectionControls && !isDragging">
            <v-circle
                v-for="(pointData, direction) in directionPoints"
                :key="direction"
                :config="{
                    x: pointData.x,
                    y: pointData.y,
                    radius: 6,
                    fill: 'white',
                    stroke: 'black',
                    strokeWidth: 1,
                }"
                @click="updatePointerDirection(pointData.direction)"
            />
        </v-group>
    </v-group>
</template>

<script>

const DIRECTIONS = {
    left: 'left',
    right: 'right',
    up: 'up',
    down: 'down',
};

export default {
    props: {
        object: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            required: false,
        },
    },

    data() {
        return {
            // Constants
            DIRECTIONS,

            // Data
            showDirectionControls: false,
            labelX: this.object.x,
            labelY: this.object.y,
            labelWidth: 0,
            labelHeight: 0,
            pointerHeight: 10,

            // UI
            directionPoints: [],
            isDragging: false,
        };
    },

    computed: {
        pointerDirection() {
            return this.object?.pointerDirection || 'down';
        },
        labelCenter() {
            return {
                x: this.object.x,
                y: this.object.y,
            };
        },

        labelConfig() {
            return {
                x: this.labelX,
                y: this.labelY,
                draggable: this.isSelected,
            };
        },
    },

    methods: {
        handleDragStart(e) {
            this.$emit('dragstart', e);
            this.isDragging = true;
        },

        handleDragEnd(e) {
            this.$emit('dragend', e);
            this.isDragging = false;
            this.updateDirectionPoints();
        },

        updatePointerDirection(direction) {
            this.$emit('update-object', { ...this.object, pointerDirection: direction });
        },
        handleDragMove(e) {
            const { target } = e;
            const { x, y } = target.attrs;

            this.labelX = x;
            this.labelY = y;

            this.$emit('dragmove', {
                ...e,
                newPos: { x, y },
            });
        },

        handleMouseEnter() {
            if (this.isSelected) {
                this.showDirectionControls = true;
            }
        },

        handleMouseLeave() {
            // this.showDirectionControls = false;
        },

        updateLabelDimensions() {
            if (this.$refs.label) {
                const label = this.$refs.label.getNode();
                this.labelWidth = label.width();
                this.labelHeight = label.height();
            }
        },

        updateDirectionPoints() {
            const offset = this.labelHeight + 20;
            const center = this.labelCenter;

            const directionArray = Object.values(DIRECTIONS).filter((direction) => direction !== this.pointerDirection);
            const points = directionArray.map((direction) => {
                switch (direction) {
                    case DIRECTIONS.right:
                        return {
                            direction,
                            x: center.x - offset,
                            y: center.y,
                        };
                    case DIRECTIONS.left:
                        return {
                            direction,
                            x: center.x + offset,
                            y: center.y,
                        };
                    case DIRECTIONS.down:
                        return {
                            direction,
                            x: center.x,
                            y: center.y - offset,
                        };
                    case DIRECTIONS.up:
                        return {
                            direction,
                            x: center.x,
                            y: center.y + offset,
                        };
                    default:
                        return {
                            direction: 'down',
                            x: center.x,
                            y: center.y,
                        };
                }
            });

            this.directionPoints = points || [];
        },

    },

    mounted() {
        this.updateLabelDimensions();
    },

    watch: {
        'object.text': {
            handler() {
                this.$nextTick(this.updateLabelDimensions);
            },
            deep: true,
            immediate: true,
        },

        'object.pointerDirection': {
            handler() {
                this.updateDirectionPoints();
            },
            deep: true,
            immediate: true,
        },

        labelHeight: {
            handler() {
                this.updateDirectionPoints();
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style scoped>
/* Add any styles you need here */
</style>
