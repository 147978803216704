<template>
    <be-widget class="detailed-usage" :has-table="true" :is-loading="isLoading || isLoadingFull">
        <template v-slot:heading>
            Detailed list view
        </template>
        <template v-slot:headingAfter>
            <detections-toggler v-model="currentView" :values="togglerValues" />
        </template>
        <template v-slot:content>
            <violations-table
                v-if="isViolationsView"
                key="violations"
                :can-load-data="canLoadData"
                @loading="isLoading = true"
                @ready="isLoading = false"
            />
            <violations-all-images-table
                v-else-if="isImagesView"
                :can-load-data="canLoadData"
                @loading="isLoading = true"
                @ready="isLoading = false"
            />
            <violations-table
                v-else-if="isPPEView"
                key="ppe"
                :can-load-data="canLoadData"
                @loading="isLoading = true"
                @ready="isLoading = false"
                type="ppe"
            />
            <violations-table
                v-else-if="isSafetyView"
                key="zones"
                :can-load-data="canLoadData"
                @loading="isLoading = true"
                @ready="isLoading = false"
                type="zones"
            />
            <annotations-table
                v-else-if="isAnnotationsView"
                key="annotations"
                :can-load-data="canLoadData"
                @loading="isLoading = true"
                @ready="isLoading = false"
            />
        </template>
    </be-widget>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DetectionsToggler from '@/components/widgets/hs/filters/DetectionsToggler';
import ViolationsTable from '@/components/widgets/hs/dataTables/ViolationsTable';
import ViolationsAllImagesTable from '@/components/widgets/hs/dataTables/ViolationsAllImagesTable';
import AnnotationsTable from '@/components/widgets/hs/dataTables/AnnotationsTable';

export default {
    name: 'DataExplorerTableWidget',

    props: {
        canLoadData: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        ViolationsAllImagesTable,
        ViolationsTable,
        DetectionsToggler,
        AnnotationsTable,
    },

    data() {
        return {
            isLoading: false,
            currentView: 'violations',
            togglerValues: [
                {
                    handle: 'violations',
                    label: 'All detections',
                },
                /*
                {
                    handle: 'ppe',
                    label: 'PPE detections',
                },
                {
                    handle: 'safety',
                    label: 'Safety zone detections',
                },
                */
                {
                    handle: 'images',
                    label: 'All images',
                },
                {
                    handle: 'annotations',
                    label: 'Annotated Videos',
                },
            ],
        };
    },

    watch: {
        currentView: {
            handler(val) {
                this.setCurrentView({ data: `hs-${val}` });
                this.$emit('view-changed', val);
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters({
            isLoadingFull: 'media/images/isLoading',
        }),
        isViolationsView() {
            return this.currentView === 'violations';
        },
        isPPEView() {
            return this.currentView === 'ppe';
        },
        isSafetyView() {
            return this.currentView === 'safety';
        },
        isImagesView() {
            return this.currentView === 'images';
        },
        isAnnotationsView() {
            return this.currentView === 'annotations';
        },
    },

    methods: {
        ...mapActions({
            setCurrentView: 'dataExport/setCurrentView',
        }),
    },
};
</script>
