var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-group',[_c('v-rect',{ref:"rect",attrs:{"config":_vm.rectConfig},on:{"click":function($event){return _vm.$emit('click', $event)},"dragstart":function($event){return _vm.$emit('dragstart', $event)},"dragend":function($event){return _vm.$emit('dragend', $event)},"dragmove":_vm.handleDragMove,"transformend":function($event){return _vm.$emit('transformend', $event)},"transform":_vm.handleTranform}}),(_vm.object.label)?_c('v-label',{attrs:{"config":{
            x: _vm.labelX,
            y: _vm.labelY,
        }},on:{"click":function($event){return _vm.$emit('click-label', $event)}}},[_c('v-tag',{attrs:{"config":{
                fill: 'white',
                stroke: 'black',
                strokeWidth: 1,
                cornerRadius: 3,
                padding: 5,
            }}}),_c('v-text',{attrs:{"config":{
                text: _vm.object.label,
                fontSize: 16,
                padding: 5,
                fill: 'black',
            }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }