import axios from 'axios';
import { get } from 'lodash';
import {
    getDefaultDataState,
    getDefaultDataGetters,
    getDefaultDataMutations,
    buildUrlParams,
    buildStartEndDateUrl,
    createDataHash,
} from '@/utils/store-helpers';
import { getApiUrl } from '@/utils/api';
import { hsAnnotationsTransformer } from '../../../../utils/transformers/hs/annotations';

const state = getDefaultDataState({ hasPagination: true });

const getters = {
    ...getDefaultDataGetters({ hasPagination: true }),

    formattedApiResults(state, getters) {
        return hsAnnotationsTransformer(getters.apiResults);
    },

    imageIds(state, getters) {
        return getters.formattedApiResults
            .map((row) => get(row, 'image.id'))
            .filter((r) => r);
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: true }),
};

const actions = {
    async loadData({ state, commit, rootState, dispatch, getters }, { perPage = null, page = null }) {
        if (page) {
            commit('setPage', { page });
        }
        if (perPage) {
            commit('setPerPage', { perPage });
        }

        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const params = buildUrlParams({
            perPage: state.perPage,
            page: state.currentPage,
        });

        const path = buildStartEndDateUrl({
            path: 'hsafety/annotations',
            startDate,
            endDate,
        });

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: 'loading' });
        try {
            const response = await axios({
                method: 'GET',
                url: getApiUrl({ path }),
                params,
            });

            const { data } = response;
            const formattedData = {
                count: data.count,
                results: data.results,
                next: data.next,
                previous: data.previous,
            };

            commit('setStatus', { status: 'success' });
            commit('setData', { data: formattedData });
            commit('setDataHash', { dataHash });

            dispatch('media/images/preload', { ids: getters.imageIds }, { root: true });

            return formattedData;
        } catch (err) {
            console.error(err);
            commit('setStatus', { status: 'error' });
            return null;
        }
    },

    async exportData() {
        return null;
    },

    async exportDataUsingCursor() {
        return null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
