import { get, isArray, isEmpty } from 'lodash';

export function hsAnnotationsTransformerRow(row) {
    const { image } = row;

    const siteName = get(image, 'camera.site.name', '');
    const cameraName = get(image, 'camera.name', '');
    const cameraId = get(image, 'camera.id', null);
    const videoId = get(image, 'video', null);

    return {
        ...row,
        siteName,
        cameraName,
        video: videoId,
        camera: {
            id: cameraId,
        },
    };
}

export function hsAnnotationsTransformer(rawData) {
    if (!isArray(rawData) || isEmpty(rawData)) {
        return [];
    }

    return rawData.map((item) => hsAnnotationsTransformerRow(item));
}
