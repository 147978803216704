<template>
    <div class="sites-single">
        <div v-if="site" class="sites-single__heading">
            <div class="sites-single__heading__map">
                <site-map :site="site" />
            </div>
            <div class="sites-single__heading__content">
                <div class="app-container">
                    <be-page-header :app-container="false">
                        <template v-slot:default>
                            {{ site.name }}
                        </template>
                        <template v-slot:cta>
                            <b-button class="be-button is-secondary is-wider" @click="toggleEditPopup">Edit</b-button>
                        </template>
                        <template v-slot:back>
                            <b-button
                                tag="router-link"
                                type="is-light"
                                to="/dashboard/sites"
                                class="be-button is-back"
                                icon-left="chevron-left"
                            />
                        </template>
                    </be-page-header>
                </div>
            </div>
        </div>
        <div class="sites-single__inner app-container">
            <b-loading :can-cancel="false" :is-full-page="false" :active="isLoading || isCamerasLoading" />
            <div class="sites-single__main" v-if="site">
                <div class="columns is-multiline sites-single__info">
                    <div class="column is-6 sites-single__info__col">
                        <div class="card is-rounded">
                            <div class="card-content">
                                <h3 class="title-widget mb-3">Site Details</h3>
                                <be-info label="Address" class="mb-4">
                                    <p>{{ site.address }}</p>
                                </be-info>
                                <be-info label="Latitude / Longitude">
                                    <p>{{ latlng }}</p>
                                </be-info>
                            </div>
                        </div>
                    </div>
                    <div class="column is-6 sites-single__info__col">
                        <div class="card is-rounded">
                            <div class="card-content">
                                <h3 class="title-widget mb-3">Tags</h3>
                                <be-info>
                                    <be-tags readonly v-model="siteTags" />
                                </be-info>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sites-single__cameras">
                    <div class="sites-single__cameras__header">
                        <h3 class="sites-single__cameras__header__title label-large">Site Cameras</h3>
                        <b-button
                            icon-left="plus"
                            type="is-primary"
                            class="be-button is-wider px-4"
                            size="is-small"
                            @click="openCameraPopup()"
                        >
                            Add New Camera
                        </b-button>
                    </div>
                    <div class="columns is-multiline">
                        <div
                            v-for="(camera, index) in cameras"
                            :key="`camera${index}`"
                            class="sites-single__column column is-6-tablet is-4-desktop is-3-widescreen"
                        >
                            <component
                                :is="cameraItemComponent"
                                :key="camera.id"
                                :camera="camera"
                                :site="site"
                            />
                        </div>
                    </div>
                    <div class="sites-single__pagination">
                        <be-pagination
                            v-if="displayPagination"
                            key="site-cameras"
                            :per-page.sync="perPage"
                            :per-page-values="perPageValues"
                            :current-page.sync="currentPage"
                            :count="camerasCount"
                        />
                    </div>
                </div>
            </div>
            <div v-else-if="isError" class="py-5 content has-text-grey-lighter has-text-centered">
                <p>Invalid site</p>
                <p>
                    <b-button
                        tag="router-link"
                        to="/dashboard/sites"
                        class="be-button is-outline is-wider"
                    >
                        Back
                    </b-button>
                </p>
            </div>
            <component
                v-if="manageCameraActive"
                :is="cameraPopupComponent"
                :site="site"
                :key="`new-${site}-camera`"
                :active="manageCameraActive"
                @close="closeCameraPopup()"
            />
            <manage-site-popup
                v-if="site && editActive"
                :site="site"
                :active="editActive"
                @close="toggleEditPopup"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { find, get } from 'lodash';
import { getDms } from '@/utils/map';
import { DEFAULT_SITES_PAGINATION_PER_PAGE, SITE_TYPES, SITES_PAGINATION_PER_PAGE } from '@/utils/sites/constants';
import CameraItem from '@/components/local/sites/CameraItem';
import ManageSitePopup from '@/components/local/sites/ManageSitePopup';
import SiteMap from '@/components/local/sites/SiteMap';
import BeTags from '@/components/global/BeTags';
import BeInfo from '@/components/global/BeInfo';
import ManageCameraPopup from '@/components/local/sites/ManageCameraPopup';
import BePagination from '@/components/global/BePagination';
import ManageHSCameraPopup from '@/components/local/sites/hs/ManageHSCameraPopup';
import HSCameraItem from '@/components/local/sites/hs/HSCameraItem';

export default {
    components: {
        BeInfo,
        BePagination,
        SiteMap,
        BeTags,
        ManageSitePopup,
        ManageCameraPopup,
        ManageHSCameraPopup,
        CameraItem,
        HSCameraItem,
    },

    data() {
        return {
            isTagsLoading: false,
            isError: false,
            editActive: false,
            manageCameraActive: false,
            perPageValues: [
                ...SITES_PAGINATION_PER_PAGE,
            ],
        };
    },

    computed: {
        ...mapState('sites', ['sitesFull', 'sitesCameras', 'camerasCount', 'camerasPerPage', 'camerasCurrentPage']),
        ...mapGetters('sites', ['isLoading', 'isCamerasLoading']),

        siteId() {
            return this.$route.params.guid;
        },

        site() {
            return find(this.sitesFull, { id: this.siteId });
        },

        siteCameras() {
            return find(this.sitesCameras, { id: this.siteId });
        },

        cameras() {
            const cams = get(this.siteCameras, 'cameras', []);
            return [...cams].sort((a, b) => a.name.localeCompare(b.name));
        },

        displayPagination() {
            return !(
                this.camerasCurrentPage === 1
                && this.camerasPerPage === DEFAULT_SITES_PAGINATION_PER_PAGE
                && this.camerasCount < DEFAULT_SITES_PAGINATION_PER_PAGE
            );
        },

        perPage: {
            get() {
                return this.camerasPerPage;
            },
            set(perPage) {
                return this.setCamerasPerPage({ perPage });
            },
        },

        currentPage: {
            get() {
                return this.camerasCurrentPage;
            },
            set(currPage) {
                const page = currPage && currPage > 0 ? currPage : 1;
                return this.setCamerasPage({ page });
            },
        },

        latlng() {
            const lat = Math.round(this.site.lat * 100) / 100;
            const lng = Math.round(this.site.lng * 100) / 100;

            const coords = [
                getDms(lat, 'lat', true),
                getDms(lng, 'lng', true),
            ];

            return coords.join(', ');
        },

        siteTags: {
            get() {
                return this.site && this.site.tags
                    ? this.site.tags
                    : [];
            },
            set(tags) {
                this.isTagsLoading = true;
                this.updateSiteTags({ siteId: this.siteId, tags })
                    .finally(() => {
                        this.isTagsLoading = false;
                    });
            },
        },

        isHS() {
            return this.site
                && this.site.site_type === SITE_TYPES.HS;
        },

        isCarpark() {
            return this.site
                && this.site.site_type === SITE_TYPES.CARPARK;
        },

        cameraItemComponent() {
            return this.isHS
                ? 'h-s-camera-item'
                : 'camera-item';
        },

        cameraPopupComponent() {
            return this.isHS
                ? 'manage-h-s-camera-popup'
                : 'manage-camera-popup';
        },
    },

    watch: {
        perPage(val) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    per_page: val !== DEFAULT_SITES_PAGINATION_PER_PAGE ? val : undefined,
                },
            })
                .catch(() => ({}));
            this.getSiteCameras({ siteId: this.siteId, force: true });
        },
        currentPage(val) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    page: val > 1 ? val : undefined,
                },
            })
                .catch(() => ({}));
            this.getSiteCameras({ siteId: this.siteId, force: true });
        },
    },

    mounted() {
        // reset pagination
        this.setCamerasPage({ page: Number(this.$route.query.page) || 1 });
        this.setCamerasPerPage({ perPage: Number(this.$route.query.per_page) || DEFAULT_SITES_PAGINATION_PER_PAGE });

        this.getSite({ siteId: this.siteId, includeCameras: false })
            .then(() => (
                this.getSiteCameras({ siteId: this.siteId, force: true })
            ))
            .catch(() => {
                this.isError = true;
            });
    },

    methods: {
        ...mapMutations('sites', ['setCamerasPage', 'setCamerasPerPage']),
        ...mapActions('sites', ['getSite', 'updateSiteTags', 'getSiteCameras']),

        toggleEditPopup() {
            this.editActive = !this.editActive;
        },

        openCameraPopup() {
            this.manageCameraActive = true;
        },

        closeCameraPopup() {
            this.manageCameraActive = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.sites-single {
    &__heading {
        position: relative;

        &__map {
            height: 200px;
        }

        &__content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
        }
    }

    &__inner {
        min-height: 300px;
        position: relative;
        overflow: hidden;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 2em;
        @include mobile {
            margin-bottom: 1em;
        }
    }

    h5 {
        font-weight: $weight-bold;
        font-size: 14px;
        color: $grey-light;
        margin-bottom: 12px;
        @include mobile {
            margin-bottom: 20px;
        }
    }

    &__info {
        &__col {
            display: flex;
            align-items: stretch;
            flex-direction: column;

            .card {
                flex: 1;
            }
        }
    }

    &__cameras {
        &__header {
            margin-bottom: 1rem;
            @include tablet {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &__title {
                @include mobile {
                    margin-bottom: .5rem;
                }
            }
        }
    }
}
</style>
