import { get, isArray, isEmpty } from 'lodash';
import { convertApiStringArr } from '@/utils/str';
import { transformImageDetectionsObject } from '@/utils/transformers/detections';

export function hsDetectionsTransformerRow(row) {
    const { image, is_safe: isSafe = false } = row;

    const riskLevel = get(row, 'risk_level', 0);
    const siteName = get(image, 'camera.site.name', '');
    const cameraName = get(image, 'camera.name', '');
    const tags = get(image, 'camera.tags', []).map((t) => t.slug);
    const displayIsSafe = isSafe && riskLevel >= 1.28;

    return {
        ...row,
        siteName,
        cameraName,
        tags,
        isSafe: displayIsSafe,
        rowType: 'hs',
        detections: transformImageDetectionsObject({ image, type: 'hs' }),
        confidences: convertApiStringArr(row.confidence),
        video: get(image, 'video', null),
        camera: {
            id: get(image, 'camera.id', null),
        },
    };
}

export function hsDetectionsTransformer(rawData) {
    if (!isArray(rawData) || isEmpty(rawData)) {
        return [];
    }

    return rawData.map((item) => hsDetectionsTransformerRow(item));
}
