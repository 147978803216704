var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-group',[_c('v-label',{ref:"label",attrs:{"config":_vm.labelConfig},on:{"click":function($event){return _vm.$emit('click', $event)},"dragstart":_vm.handleDragStart,"dragend":_vm.handleDragEnd,"dragmove":_vm.handleDragMove,"transformend":function($event){return _vm.$emit('transformend', $event)},"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},[_c('v-tag',{attrs:{"config":{
                /*
                pointerDirection: 'down',
                pointerWidth: 10,
                pointerLength: 10,
                fill: 'white',
                stroke: this.isSelected ? 'blue' : 'black',
                strokeWidth: 1,
                cornerRadius: 3,
                padding: 5,
                */
                fill: 'white',
                stroke: this.isSelected ? 'blue' : 'grey',
                pointerDirection: this.pointerDirection,
                pointerWidth: 10,
                pointerHeight: this.pointerHeight,
                lineJoin: 'round',
            }}}),_c('v-text',{attrs:{"config":{
                text: _vm.object.label,
                fontSize: 16,
                padding: 5,
                fill: 'black',
            }}})],1),(_vm.isSelected && _vm.showDirectionControls && !_vm.isDragging)?_c('v-group',_vm._l((_vm.directionPoints),function(pointData,direction){return _c('v-circle',{key:direction,attrs:{"config":{
                x: pointData.x,
                y: pointData.y,
                radius: 6,
                fill: 'white',
                stroke: 'black',
                strokeWidth: 1,
            }},on:{"click":function($event){return _vm.updatePointerDirection(pointData.direction)}}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }