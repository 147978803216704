<template>
    <v-group>
        <v-rect
            ref="rect"
            :config="rectConfig"
            @click="$emit('click', $event)"
            @dragstart="$emit('dragstart', $event)"
            @dragend="$emit('dragend', $event)"
            @dragmove="handleDragMove"
            @transformend="$emit('transformend', $event)"
            @transform="handleTranform"
        />

        <!-- Label with background -->
        <v-label
            v-if="object.label"
            :config="{
                x: labelX,
                y: labelY,
            }"
            @click="$emit('click-label', $event)"
        >
            <v-tag
                :config="{
                    fill: 'white',
                    stroke: 'black',
                    strokeWidth: 1,
                    cornerRadius: 3,
                    padding: 5,
                }"
            />
            <v-text
                :config="{
                    text: object.label,
                    fontSize: 16,
                    padding: 5,
                    fill: 'black',
                }"
            />
        </v-label>
    </v-group>
</template>

<script>
export default {
    props: {
        object: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            required: false,
        },
        canResize: {
            type: Boolean,
            required: false,
        },
    },

    data() {
        return {
            labelX: this.object.x,
            labelY: this.object.y - 25,
        };
    },

    methods: {
        handleDragMove(e) {
            const { target } = e;
            const { x, y } = target.attrs;

            this.labelX = x;
            this.labelY = y - 25;

            this.$emit('dragmove', e);
        },

        handleTranform(e) {
            const { target } = e;
            const { x, y } = target.attrs;

            this.labelX = x;
            this.labelY = y - 25;
        },
    },

    emits: ['click'],

    computed: {
        rectConfig() {
            return {
                x: this.object.x,
                y: this.object.y,
                width: this.object.w,
                height: this.object.h,
                fill: this.object.fillColor,
                stroke: this.isSelected ? 'blue' : this.object.borderColor,
                strokeWidth: this.isSelected ? 6 : 4,
                strokeDash: this.isSelected ? [10, 10] : null,
                strokeScaleEnabled: false,
                draggable: this.isSelected,
            };
        },
    },
};
</script>

<style scoped>
/* Add any styles you need here */
</style>
