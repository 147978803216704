<template>
    <be-widget class="chart" :is-loading="isLoading" :content-classes="['is-align-bottom']">
        <template v-slot:heading>{{ widgetTitle }}</template>
        <template v-slot:content>
            <div class="chart__chart">
                <line-chart class="chart__wrapper" :chart-data="chartData" :options="options" />
            </div>
        </template>
    </be-widget>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import LineChart from '@/utils/chartjs/LineChart';
import { CHART_COLORS } from '@/utils/constants';

const getChartDataDefaults = () => ({
    labels: [],
    datasets: [],
});

export default {
    components: {
        LineChart,
    },

    data() {
        return {
            chartData: getChartDataDefaults(),
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        usePointStyle: true,
                    },
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'day',
                            displayFormats: {
                                day: 'DD MMM YYYY',
                            },
                        },
                        bounds: 'ticks',
                        distribution: 'series',
                        ticks: {
                            source: 'labels',
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            min: 0,
                            callback: (value) => {
                                if (value < 1000) {
                                    return value;
                                }

                                if (value < 1000000) {
                                    return `${value / 1000}k`;
                                }

                                return `${value / 1000000}M`;
                            },
                        },
                    }],
                },
                elements: {
                    point: {
                        pointStyle: 'line',
                    },
                },
            },
        };
    },

    computed: {
        ...mapGetters('hs/riskLevelSummary', ['getCountData', 'getTickCount', 'getTickDuration', 'isLoading']),
        ...mapGetters('dataFilters', ['getDateRange', 'getFilterHash']),

        widgetTitle() {
            return 'Observation Map';
        },

        riskLevelCount() {
            return this.getCountData;
        },

        tickCount() {
            return this.getTickCount;
        },

        tickDuration() {
            return this.getTickDuration;
        },
    },

    mounted() {
        this.loadData();
        this.updateChartData();
    },

    watch: {
        getCountData: {
            handler(newValue) {
                if (newValue) {
                    this.updateChartData();
                }
            },
            immediate: false,
            deep: true,
        },

        getFilterHash() {
            this.loadData();
        },
    },

    methods: {
        ...mapActions('hs/riskLevelSummary', ['loadData']),

        canPopulateChart() {
            return this.getDateRange.start && this.getDateRange.end
                && this.getDateRange.start <= this.getDateRange.end;
        },

        updateChartData() {
            if (!this.canPopulateChart()) {
                this.resetChartData();
                return;
            }

            this.$nextTick(() => {
                const startDate = dayjs(this.getDateRange.start);
                const dates = Array.from({ length: this.tickCount }, (_, index) => startDate.add(index * this.tickDuration, 'millisecond').toDate());

                if (dates.length > 0) {
                    dates[dates.length - 1] = dayjs(this.getDateRange.end).toDate();
                }

                this.chartData = {
                    labels: dates,
                    datasets: [
                        {
                            label: 'Low',
                            fill: false,
                            backgroundColor: CHART_COLORS.green,
                            borderColor: CHART_COLORS.green,
                            data: this.riskLevelCount.low.map((count, index) => ({
                                x: dates[index],
                                y: count,
                            })),
                        },
                        {
                            label: 'Medium',
                            fill: false,
                            backgroundColor: CHART_COLORS.yellow,
                            borderColor: CHART_COLORS.yellow,
                            data: this.riskLevelCount.medium.map((count, index) => ({
                                x: dates[index],
                                y: count,
                            })),
                        },
                        {
                            label: 'High',
                            fill: false,
                            backgroundColor: CHART_COLORS.red,
                            borderColor: CHART_COLORS.red,
                            data: this.riskLevelCount.high.map((count, index) => ({
                                x: dates[index],
                                y: count,
                            })),
                        },
                    ],
                };
            });
        },

        resetChartData() {
            this.chartData = getChartDataDefaults();
        },
    },
};
</script>

<style lang="scss" scoped>
.chart {
    &__toggler {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }

    &__wrapper {
        position: relative;
        height: 500px;
        width: 100%;
    }
}
</style>
