<template>
    <div class="media-3d">
        <b-loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
        <template>
            <div class="media-image__inner" @dblclick.prevent="onZoom">
                <div ref="container" class="media-image__container" />
            </div>
        </template>
    </div>
</template>

<script>
/* eslint-disable no-bitwise */
/* eslint-disable global-require */
import { dragscroll } from 'vue-dragscroll/src/main';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import {
    STATUS_ERROR,
    STATUS_LOADING,
    STATUS_PENDING,
    STATUS_READY,
} from '@/utils/constants';
import { getTansformationConfig } from '@/utils/canvas';

export default {
    directives: {
        dragscroll,
    },

    props: {
        active: {
            type: Boolean,
            default: false,
        },
        depthMapUrl: {
            type: String,
            default: '',
        },
        imageId: {
            type: String,
            default: '',
        },
        detections: {
            type: Object,
            default: null,
        },
        camera: {
            type: [String, Object],
            default: null,
        },
        displayRoi: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        imageType: {
            type: String,
            default: 'vehicle',
        },
        rowType: {
            type: String,
            default: '',
        },
        imageWithDetail: {
            type: Object,
            required: true,
        },
    },

    mounted() {
        this.$bus.$on('resize', this.resize);
        this.$once('hook:beforeDestroy', () => {
            this.$bus.$off('resize', this.resize);
        });

        const canvas = this.$refs.container;
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 1, 5000);
        camera.position.z = 500;
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);
        canvas.appendChild(renderer.domElement);

        const loadImage = async (url) => {
            const response = await fetch(url);
            const blob = await response.blob();
            const bitmap = await createImageBitmap(blob);
            const canvas = document.createElement('canvas');
            canvas.width = bitmap.width;
            canvas.height = bitmap.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(bitmap, 0, 0);
            return ctx.getImageData(0, 0, canvas.width, canvas.height);
        };

        // const cameraObj = this.camera;
        // const horizontalFov = cameraObj.horizontal_fov || 90;
        // const verticalFov = cameraObj.vertical_fov || 60;

        loadImage(this.depthMapUrl).then((depthData) => {
            const geometry = new THREE.BufferGeometry();
            const vertices = [];
            const colors = [];

            // Create video element and canvas for texture
            const video = document.createElement('video');
            video.src = this.videoUrl;
            video.crossOrigin = 'anonymous';

            const videoTexture = new THREE.VideoTexture(video);
            videoTexture.minFilter = THREE.LinearFilter;
            videoTexture.magFilter = THREE.LinearFilter;

            video.addEventListener('loadeddata', () => {
                const tempCanvas = document.createElement('canvas');
                const tempCtx = tempCanvas.getContext('2d');
                tempCanvas.width = video.videoWidth;
                tempCanvas.height = video.videoHeight;
                tempCtx.drawImage(video, 0, 0);
                const colorData = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height).data;

                console.log(depthData.width, depthData.height);
                console.log(video.videoWidth, video.videoHeight);

                for (let y = 0; y < depthData.height - 1; y++) {
                    for (let x = 0; x < depthData.width - 1; x++) {
                        const addPoint = (px, py, depth, overrideColor = null) => {
                            // Convert pixel coordinates to normalized device coordinates (-1 to 1)
                            // const ndcX = (px / depthData.width) * 2 - 1;
                            // const ndcY = -((py / depthData.height) * 2 - 1); // Flip Y axis

                            // Convert FOV from degrees to radians
                            // const horizontalFovRad = (horizontalFov * Math.PI) / 180;
                            // const verticalFovRad = (verticalFov * Math.PI) / 180;

                            // // Calculate the tangent values
                            // const tanHorizontal = Math.tan(horizontalFovRad / 2);
                            // const tanVertical = Math.tan(verticalFovRad / 2);

                            // // Calculate real-world X and Y coordinates based on FOV and depth
                            // const posX = ndcX * depth * tanHorizontal;
                            // const posY = ndcY * depth * tanVertical;
                            const posX = px - depthData.width / 2;
                            const posY = -py + depthData.height / 2;
                            const posZ = -depth;

                            const vertex = new THREE.Vector3(posX, posY, posZ);
                            vertices.push(vertex.x, vertex.y, vertex.z);

                            const colorIndex = (Math.floor(py) * depthData.width + Math.floor(px)) * 4;
                            colors.push(
                                overrideColor ? overrideColor[0] : colorData[colorIndex] / 255,
                                overrideColor ? overrideColor[1] : colorData[colorIndex + 1] / 255,
                                overrideColor ? overrideColor[2] : colorData[colorIndex + 2] / 255,
                            );
                        };

                        // Get depths for current point and neighbors
                        const getDepth = (px, py) => {
                            const idx = (py * depthData.width + px) * 4;
                            const blue = depthData.data[idx + 2];
                            const green = depthData.data[idx + 1];
                            return (((blue << 8) + green) / 65535) * 1250;
                        };

                        const d1 = getDepth(x, y);
                        const dx1 = getDepth(Math.max(x - 1, 0), y);
                        const dx2 = getDepth(Math.min(x + 1, depthData.width - 1), y);
                        const dy1 = getDepth(x, Math.max(y - 1, 0));
                        const dy2 = getDepth(x, Math.min(y + 1, depthData.height - 1));

                        // Add original point
                        addPoint(x, y, d1);

                        // Check for steep depth changes and interpolate
                        const depthThreshold = 50; // Adjust this value to control interpolation sensitivity
                        const steps = 10; // Number of interpolation points

                        // Interpolate horizontally if needed
                        if (Math.abs(dx2 - dx1) > depthThreshold) {
                            for (let i = 1; i < steps; i++) {
                                const t = i / steps;
                                const interpX = x + t;
                                const interpDepth = dx1 + (dx2 - dx1) * t;
                                addPoint(interpX, y, interpDepth); // , [1, 0, 0]);
                            }
                        }

                        // Interpolate vertically if needed
                        if (Math.abs(dy2 - dy1) > depthThreshold) {
                            for (let i = 1; i < steps; i++) {
                                const t = i / steps;
                                const interpY = y + t;
                                const interpDepth = dy1 + (dy2 - dy1) * t;
                                addPoint(x, interpY, interpDepth); // , [1, 0, 0]);
                            }
                        }
                    }
                }

                geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
                geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
                const material = new THREE.PointsMaterial({
                    size: 2.5, // Smaller point size since we have more points
                    vertexColors: true,
                    sizeAttenuation: true, // Points get smaller with distance
                    opacity: 0.8,
                    transparent: true,
                });
                const pointCloud = new THREE.Points(geometry, material);
                scene.add(pointCloud);

                const controls = new OrbitControls(camera, renderer.domElement);
                controls.target.set(0, 0, -500);
                let boundAnimation = null;
                function animate() {
                    requestAnimationFrame(boundAnimation);
                    controls.update();
                    renderer.render(scene, camera);
                }

                boundAnimation = animate.bind(this);
                boundAnimation();

                this.imageStatus = STATUS_READY;
            });

            // Play video to get current frame
            video.currentTime = 0;
            video.play().then(() => {
                video.pause();
            });
        });
    },

    data() {
        return {
            imageStatus: STATUS_PENDING,
            message: 'No image provided',
            zoomed: false,
            containerSize: null,
            imageSize: {
                width: 0,
                height: 0,
            },
            scale: 1,
            currentCoordinate: { x: 0, y: 0 },
            isTooltipShown: false,
            tooltipText: '',
        };
    },

    computed: {
        isHS() {
            return this.rowType === 'hs';
        },

        isReady() {
            return this.imageStatus === STATUS_READY;
        },

        isError() {
            return this.imageStatus === STATUS_ERROR;
        },

        isLoading() {
            return this.imageStatus === STATUS_LOADING || this.loading;
        },

        detectionsSize() {
            const width = 1024;
            const height = 768;

            return {
                width,
                height,
                ratio: width && width ? width / height : 1,
            };
        },

        sceneStyles() {
            let width;
            let height;

            if (this.detectionsSize.ratio > this.containerSize.ratio) {
                ({ width } = this.containerSize);
                height = parseInt(
                    this.containerSize.width / this.detectionsSize.ratio,
                    10,
                );
            } else {
                width = parseInt(
                    this.containerSize.height * this.detectionsSize.ratio,
                    10,
                );
                ({ height } = this.containerSize);
            }

            return {
                width: `${width}px`,
                height: `${height}px`,
            };
        },

        videoUrl() {
            return this.imageWithDetail?.videoFile || '';
        },
    },

    watch: {
        active: {
            handler(val) {
                if (val) {
                    this.resize();
                }
            },
            immediate: true,
        },

        url: {
            handler() {
                this.imageStatus = STATUS_LOADING;
            },
            immediate: true,
        },

        isReady(val) {
            this.resize();
            if (val) {
                this.$emit('load');
            }
        },
    },

    methods: {
        toggleZoom(force = null, center = null) {
            this.zoomed = force === null ? !this.zoomed : force;
            this.$nextTick(() => {
                this.resize();

                if (center && this.$refs.container) {
                    this.$refs.container.scrollLeft = center.x;
                    this.$refs.container.scrollTop = center.y;
                }
            });
        },

        onZoom(event) {
            if (this.zoomed) {
                return this.toggleZoom(false);
            }
            return this.toggleZoom(true, {
                x: event.offsetX,
                y: event.offsetY,
            });
        },

        resize() {
            this.$nextTick(() => {
                if (this.$refs.container) {
                    const width = this.$refs.container.offsetWidth;
                    const height = this.$refs.container.offsetHeight;
                    this.containerSize = {
                        width,
                        height,
                        ratio: width / height,
                    };
                }

                const { image } = this.$refs;
                if (image && image.naturalWidth && image.naturalHeight) {
                    this.imageSize = {
                        width: image.clientWidth,
                        height: image.clientHeight,
                    };
                    const { scale } = getTansformationConfig({
                        clientWidth: image.clientWidth,
                        clientHeight: image.clientHeight,
                        mediaWidth: image.naturalWidth,
                        mediaHeight: image.naturalHeight,
                    });
                    this.scale = scale;
                }
            });
        },

        onImageLoad() {
            this.imageStatus = STATUS_READY;
        },

        onImageError() {
            this.imageStatus = STATUS_ERROR;
            this.message = 'There was an error generating the 3D visualisation.';
        },
        onMouseMove(event) {
            this.currentCoordinate = {
                x: event.offsetX,
                y: event.offsetY,
            };
        },
        showTooltip(show = false, text = '') {
            this.isTooltipShown = show;
            this.tooltipText = text;

            const tooltip = this.$el.querySelector('.tooltip');
            tooltip.style.left = `${this.currentCoordinate.x - 50}px`;
            tooltip.style.top = `${this.currentCoordinate.y - 50}px`;
        },
    },
};
</script>

<style lang="scss" scoped>
.media-image {
    position: relative;
    min-width: 300px;
    min-height: 200px;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    align-items: stretch;

    &__inner {
        flex: 1;
        display: flex;
        align-items: center;
        user-select: none;
        overflow: hidden;
    }

    .grab-bing {
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    &__container {
        flex: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;

        &.is-zoomed {
            display: block;
        }
    }

    &__scene {
        position: relative;
        width: 100%;

        &.is-zoomed {
            cursor: grab;
            width: 200%;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;
        user-select: none;
        @include transition-default;
    }

    &__canvas {
        position: absolute;
        left: 0;
        top: 0;
    }

    &__error {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: $grey-light;
        font-size: 0.9em;
        cursor: default;
    }

    .controls {
        position: absolute;
        @include z-index(above);
        left: 0;
        right: 0;
        bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__control {
            @include z-index(above);
            padding: 0;
            margin: 0 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: 36px;
            opacity: 0.6;
            transition: opacity $speed $easing;

            &,
            &:active,
            &:focus {
                border-color: transparent;
                box-shadow: none;
                outline: none;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}

.tooltip {
    display: block;
    position: absolute;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    z-index: 9999; // Ensure the tooltip appears above other elements
}
</style>
