<template>
    <v-group v-if="isVisible" ref="group">
        <v-line :config="proximityLineConfig" />
        <v-line :config="ellipseConfig" v-if="hasEllipsePoints" />
        <v-rect :config="proximityBoxConfig" />
        <v-text :config="proximityTextConfig" />
    </v-group>
</template>

<script>
import { COLORS, PROXIMITY_DISTANCE_THRESHOLD } from '@/utils/constants';
import { convertToCanvasLine } from '@/utils/canvas';

export default {
    props: {
        result: {
            type: Object,
            required: true,
        },
        scale: {
            type: Number,
            required: true,
        },
    },

    computed: {
        isVisible() {
            return this.result.distance < PROXIMITY_DISTANCE_THRESHOLD;
        },

        proximityLineConfig() {
            return {
                points: this.getLinePoints(),
                stroke: this.proximityLineColor,
                strokeWidth: 2,
                opacity: this.result.opacity !== undefined ? this.result.opacity : 0.8,
            };
        },

        ellipseConfig() {
            return {
                points: this.getEllipsePoints(),
                stroke: COLORS.yellow,
                strokeWidth: 1,
                opacity: 0.5,
                closed: true,
            };
        },

        hasEllipsePoints() {
            return this.result.ellipse_points_2d && this.result.ellipse_points_2d.length > 0;
        },

        proximityTextConfig() {
            const { x, y } = this.getMidPoint();
            return {
                text: this.distanceText,
                x,
                y,
                offsetY: 10,
                fontSize: 16,
                fill: COLORS.black,
                strokeEnabled: true,
            };
        },

        proximityBoxConfig() {
            const { x, y } = this.getMidPoint();
            return {
                x,
                y,
                width: this.distanceText.length * 10,
                height: 15,
                offsetY: 11,
                offsetX: 2,
                fill: COLORS.white,
                opacity: this.result.opacity !== undefined ? this.result.opacity : 0.8,
            };
        },

        proximityLineColor() {
            if (this.result.risk_level === 'danger') {
                return COLORS.red;
            }

            if (this.result.risk_level === 'caution') {
                return COLORS.yellow;
            }
            return COLORS.green;
        },

        distanceText() {
            return this.result.distance.toFixed(2).toString();
        },
    },

    methods: {
        getLinePoints() {
            const { box1, box2, closest_point_2d: closestPoint2d } = this.result;

            // Find which box is the person
            const personBox = box1.class_id === 'person' ? box1 : box2;
            const forkliftBox = box1.class_id === 'person' ? box2 : box1;

            // Calculate bottom center of person box
            const personBottom = {
                x: (personBox.x1 + personBox.x2) / 2,
                y: personBox.y2, // Bottom of the box
            };

            let forkliftPoint = closestPoint2d;
            if (!closestPoint2d || closestPoint2d.length === 0) {
                forkliftPoint = [
                    (forkliftBox.x1 + forkliftBox.x2) / 2,
                    (forkliftBox.y1 + forkliftBox.y2) / 2,
                ];
            }

            // Convert to canvas coordinates
            const canvasLine = convertToCanvasLine({
                str: [
                    personBottom.x,
                    personBottom.y,
                    forkliftPoint[0],
                    forkliftPoint[1],
                ],
                scale: this.scale,
                translate: this.translate,
            });

            return canvasLine;
        },

        getEllipsePoints() {
            const { ellipse_points_2d: ellipsePoints2d } = this.result;
            if (!ellipsePoints2d || ellipsePoints2d.length === 0) {
                return [];
            }

            // Convert all ellipse points to canvas coordinates
            const canvasPoints = [];
            ellipsePoints2d.forEach((point) => {
                const canvasPoint = convertToCanvasLine({
                    str: [point[0], point[1]],
                    scale: this.scale,
                    translate: this.translate,
                });
                canvasPoints.push(canvasPoint[0], canvasPoint[1]);
            });

            return canvasPoints;
        },

        getMidPoint() {
            const points = this.getLinePoints();
            const midPoint = {
                x: (points[0] + points[2]) / 2,
                y: (points[1] + points[3]) / 2,
            };
            return midPoint;
        },
    },
};
</script>
