<template>
    <div>
        <data-table-options />
        <b-table
            :data="formattedApiResults"
            :hoverable="true"
            :selected="lastSelectedRow"
            :class="{ 'has-cursor-loading': isRowLoading }"
        >
            <b-table-column label="Id" width="1%" v-slot="props">
                <p class="has-text-centered has-text-weight-bold">
                    <a
                        href="#"
                        class="has-text-white"
                        @click.prevent="openPopup(props.row, props.index)">{{ props.index + 1 }}</a>
                </p>
            </b-table-column>
            <b-table-column label="Image" :width="imagesPreviewEnabled ? 100 : '1%'" v-slot="props">
                <image-preview
                    v-if="imagesPreviewEnabled"
                    :row="props.row"
                    @click="openPopup($event, props.index)"
                />
                <p v-else class="has-text-centered">
                    <be-button-view-image @click="openPopup(props.row, props.index)" />
                </p>
            </b-table-column>
            <b-table-column label="Time Created" width="100" v-slot="props">
                <span class="has-cursor-default">
                    <template v-if="fullDatesEnabled">
                        {{ props.row.time_created | dateTime(true) }}
                    </template>
                    <template v-else>
                        {{ props.row.time_created | dateTimeHuman }}
                    </template>
                </span>
            </b-table-column>
            <b-table-column label="Site" v-slot="props">
                {{ props.row.siteName || '-' }}
            </b-table-column>
            <b-table-column label="Camera" v-slot="props">
                {{ props.row.cameraName || '-' }}
            </b-table-column>
            <template slot="empty">
                <data-table-empty v-if="showZeroResults">
                    There are no results to display. Try changing your filter or search criteria.
                </data-table-empty>
            </template>
        </b-table>
        <be-pagination
            key="annotations"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :count="overallCount"
        />
        <media-image-popup
            :active.sync="showCarJamInfo"
            :image-with-detail="platesAndImageAndWithDetail"
            :display-arrows="formattedApiResults && formattedApiResults.length > 1"
            :loading="isLoadingFull"
            @prev="goto('prev')"
            @next="goto('next')"
            @close="closePopup"
        />
    </div>
</template>

<script>
import ImagePreview from '@/components/local/data/ImagePreview';
import BeButtonViewImage from '@/components/global/BeButtonViewImage';
import DataTableEmpty from '@/components/widgets/dataTables/DataTableEmpty';
import DataTableOptions from '@/components/local/dataTables/DataTableOptions';
import dataTableMixin from '@/mixins/dataTableMixin';
import formattersMixin from '@/mixins/formattersMixin';
import hasPopupMixin from '@/mixins/hasPopupMixin';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import detectionDetailMixin from '@/mixins/detectionDetailMixin';
import MediaImagePopup from '@/components/local/data/MediaImagePopup';
import BePagination from '@/components/global/BePagination';

export default {
    components: {
        DataTableOptions,
        ImagePreview,
        BeButtonViewImage,
        DataTableEmpty,
        MediaImagePopup,
        BePagination,
    },

    mixins: [dataTableMixin, hasPopupMixin, widgetDataMixin, formattersMixin, hasPopupMixin, detectionDetailMixin],

    data() {
        return {
            tableData: [], // This should be populated with the actual data
            lastSelectedRow: null,
            isRowLoading: false,
        };
    },
    methods: {
        loadData(payload) {
            return this.$store.dispatch('hs/explorer/annotations/loadData', payload);
        },
    },

    computed: {
        isLoading() {
            return this.$store.getters['hs/explorer/annotations/isLoading'];
        },

        showZeroResults() {
            return this.tableData.length === 0 && this.isLoading === false;
        },

        formattedApiResults() {
            return this.$store.getters['hs/explorer/annotations/formattedApiResults'];
        },

        perPage: {
            get() {
                return this.$store.state.hs.explorer.annotations.perPage;
            },
            set(perPage) {
                // this.$store.commit('dataExport/setPerPage', { data: perPage });
                return this.$store.commit('hs/explorer/annotations/setPerPage', { perPage });
            },
        },

        currentPage: {
            get() {
                return this.$store.state.hs.explorer.annotations.currentPage;
            },
            set(currPage) {
                const page = currPage && currPage > 0 ? currPage : 1;
                return this.$store.commit('hs/explorer/annotations/setPage', { page });
            },
        },

        overallCount() {
            return this.$store.getters['hs/explorer/annotations/overallCount'];
        },
    },

    watch: {
        isLoading(val) {
            if (val) {
                this.$emit('loading');
            } else {
                this.$emit('ready');
            }
        },
    },
};
</script>

<style scoped>
.safe-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: green;
}

.risk-score-cell {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}
</style>
