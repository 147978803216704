<template>
    <div class="roi-image">
        <template v-if="isError">
            <div class="roi-image__error">
                <div class="content">
                    <p>{{ message }}</p>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="roi-image__ctas mb-3">
                <div
                    class="buttons"
                    style="justify-content: center;"
                >
                    <b-tooltip
                        v-for="type in roiTypes"
                        :key="`roi${type.type}`"
                        position="is-bottom"
                        :triggers="['hover']"
                        class="roi-tooltip"
                    >
                        <b-button
                            class="be-button is-smaller"
                            :icon-left="roiType === type.type ? 'pencil-plus' : 'plus-circle-outline'"
                            :style="getRoiStyles(type.type)"
                            @click="onRoiTypeClick(type)"
                        >
                            {{ type.label }}
                        </b-button>

                        <template #content>{{ getRoiTypeLabel(type.type) }}</template>
                    </b-tooltip>
                </div>
            </div>

            <div class="rois__controls">
                <b-checkbox v-model="showCalibrationBox">
                    Show Calibration Box
                </b-checkbox>
            </div>
            <div ref="container" class="roi-image__container">
                <div ref="scene" class="roi-image__scene">
                    <img
                        ref="image"
                        :src="url"
                        class="roi-image__image"
                        @load="onImageLoad"
                        @error="onImageError"
                        :style="{ opacity: isReady ? 1 : 0 }"
                        alt=""
                    >
                    <roi-h-s-camera-image-canvas
                        ref="canvas"
                        v-if="scale && isReady"
                        :canvas-style="sceneStyles"
                        :rendered-size="renderedSize"
                        :scale="scale"
                        :rois="currentRois"
                        :roi-type="roiType"
                        :camera="camera"
                        :show-calibration-box="showCalibrationBox"
                        class="roi-image__canvas"
                    />
                    <div v-else :style="sceneStyles" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { get } from 'lodash';
import { mapState } from 'vuex';
import { STATUS_ERROR, STATUS_PENDING, STATUS_READY, ROI_TYPES } from '@/utils/constants';
import RoiHSCameraImageCanvas from '@/components/local/sites/hs/partials/RoiHSCameraImageCanvas';
import { getRoiDescription, getRoiColor } from '@/utils/roi';

export default {
    components: { RoiHSCameraImageCanvas },
    props: {
        camera: {
            type: Object,
            required: true,
        },
        image: {
            type: Object,
            required: true,
        },
        rois: {
            type: Array,
            required: true,
        },
    },

    data() {
        const width = get(this.image, 'img_width', 0);
        const height = get(this.image, 'img_height', 0);

        const realImageSize = {
            width,
            height,
            ratio: width && width ? width / height : 1,
        };

        return {
            imageStatus: STATUS_PENDING,
            message: 'No image provided',
            containerSize: null,
            realImageSize,
            imageSize: {
                width: 0,
                height: 0,
            },
            roiType: 0,
            // ensure we do not render deleted rois
            currentRois: [...this.rois].filter((roi) => !roi.deleted),
            showCalibrationBox: false,
        };
    },

    computed: {
        ...mapState('roi', ['roiTypes']),
        scale() {
            return this.renderedSize.width
                ? this.realImageSize.width / this.renderedSize.width
                : 0;
        },

        url() {
            return get(this.image, 'url');
        },

        isReady() {
            return this.imageStatus === STATUS_READY;
        },

        isError() {
            return this.imageStatus === STATUS_ERROR;
        },

        renderedSize() {
            if (!this.containerSize) {
                return {
                    width: 0,
                    height: 0,
                };
            }
            const ratio = this.realImageSize.width / this.containerSize.width;
            const height = parseInt(this.realImageSize.height / ratio, 10);
            return {
                width: this.containerSize.width,
                height,
            };
        },

        sceneStyles() {
            return {
                width: `${this.renderedSize.width}px`,
                height: `${this.renderedSize.height}px`,
            };
        },
    },

    mounted() {
        this.$bus.$on('resize', this.resize);
        this.$once('hook:beforeDestroy', () => {
            this.$bus.$off('resize', this.resize);
        });
        this.resize();
    },

    methods: {
        getRoiStyles(type) {
            const roiType = ROI_TYPES[type];
            const isSelected = this.roiType === type;
            try {
                const color = getRoiColor(roiType);
                if (isSelected) {
                    return {
                        backgroundColor: color,
                        border: 'none',
                    };
                }
                return {
                    borderColor: color,
                };
            } catch (err) {
                console.log(err);
                return {};
            }
        },

        getRoiTypeLabel(type) {
            const roiType = ROI_TYPES[type];
            try {
                return getRoiDescription(roiType);
            } catch (err) {
                console.log(err);
                return '';
            }
        },

        isDirty() {
            return false;
        },

        onImageLoad() {
            this.imageStatus = STATUS_READY;
        },

        onImageError() {
            this.imageStatus = STATUS_ERROR;
            const match = this.url.match(/Expires=(\d+)/);
            if (match) {
                if (this.$date().unix() > Number(match[1])) {
                    this.message = 'The link to this image has expired after 60 minutes of inactivity. Please refresh your browser window to reload the image.';
                    return;
                }
            }
            this.message = 'This image has been deleted as your data retention policy is set to 30 days. To review or modify this policy please contact us.';
        },

        resize() {
            this.$nextTick(() => {
                if (this.$refs.container) {
                    const width = this.$refs.container.offsetWidth;
                    const height = this.$refs.container.offsetHeight;
                    this.containerSize = {
                        width,
                        height,
                        ratio: width / height,
                    };
                }
                if (this.$refs.image) {
                    this.imageSize = {
                        width: this.$refs.image.clientWidth,
                        height: this.$refs.image.clientHeight,
                    };
                }
            });
        },

        validate() {
            return this.$refs.canvas.validate();
        },

        getSaveData() {
            return this.$refs.canvas.getSaveData();
        },

        getRoiData() {
            return this.$refs.canvas.getRoiData();
        },

        onRoiTypeClick(type) {
            this.roiType = type.type;
            this.$root.$emit('roi:camera:esc');
        },
    },
};
</script>

<style lang="scss" scoped>
.roi-image {
    position: relative;

    *:focus {
        outline: none;
    }

    &__container {
        position: relative;
        flex: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__scene {
        position: relative;
        width: 100%;
    }

    &__canvas {
        position: relative;
        z-index: 100;
    }

    &__image {
        position: absolute;
        top: 0;

        display: block;
        width: 100%;
        height: auto;
    }

    &__ctas {
        .button {
            font-weight: $weight-bold;
            color: $white;
            border: 2px solid transparent;
            background: transparent;
        }
    }

    &__controls {
        padding: 10px 0;
        border-bottom: 1px solid $border;
        margin-bottom: 10px;
    }
}

.detections-tags__tooltip {
    z-index: 100 !important;
}
</style>
