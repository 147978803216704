<template>
    <v-group>
        <v-line
            v-if="isSelected || showComment"
            :config="lineConfig"
        />
        <v-circle
            :config="dotConfig"
            @click="$emit('click', $event)"
            @dragend="$emit('dragend', $event)"
            @dragmove="handleDotDragMove"
            @mouseover="handleMouseOverGrab"
            @mouseout="handleMouseOutGrab"

        />
        <v-group
            v-if="isSelected || showComment"
            :draggable="isSelected"
            @dragend="handleCommentDragEnd"
            @dragmove="handleCommentDragMove"
            @mouseover="handleMouseOverGrab"
            @mouseout="handleMouseOutGrab"
        >
            <v-rect
                :ref="`comment-rect-${object.id}`"
                :config="commentConfig"
            />
            <v-text
                :ref="`comment-text-${object.id}`"
                :config="textConfig"
            />

            <v-path
                v-if="!isEditing && !showComment"
                :ref="`edit-icon-${object.id}`"
                :config="editIconConfig"
                @mousedown="handlePreventDrag"
            />
            <v-path
                v-else-if="!showComment"
                :ref="`submit-icon-${object.id}`"
                :config="submitIconConfig"
                @mousedown="handlePreventDrag"
            />

            <v-circle
                v-if="!showComment"
                :ref="`icon-button-${object.id}`"
                :config="iconButtonConfig"
                @mouseover="handleMouseOverButton"
                @mouseout="handleMouseOutButton"
                @click="handleClick"
                @mousedown="handlePreventDrag"
            />
        </v-group>
    </v-group>
</template>

<script>
import { MODES } from '../VideoAnnotationConstants';

export default {
    name: 'CommentObj',

    props: {
        object: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        stage: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            required: true,
        },
        tool: {
            type: Object,
            required: true,
        },
        scale: {
            type: Number,
            require: false,
            default: 1,
        },
        defaultCursor: {
            type: String,
            required: false,
            default: 'default',
        },
        boundDrag: {
            type: Function,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            textarea: null,
            rectHeight: 50,
            defaultRectWidth: 200,
            isEditing: false,
            showComment: false,

            currentCursor: this.defaultCursor,
        };
    },

    methods: {
        handleUpdateText(text) {
            const updatedObj = { ...this.object };

            updatedObj.comment.text = text;

            this.$emit('object-updated', updatedObj);
        },

        handleCommentDragMove(e) {
            const { x, y } = e.target.attrs;
            const copyObj = { ...this.object };

            copyObj.comment.x = x * this.scale;
            copyObj.comment.y = y * this.scale;
            this.$emit('object-updated', copyObj);
        },

        handleDotDragMove(e) {
            const { x, y } = e.target.position();
            const copyObj = { ...this.object };

            copyObj.x = x * this.scale;
            copyObj.y = y * this.scale;

            this.$emit('object-updated', copyObj);
        },

        handleCommentDragEnd() {
            const { x, y } = this.rectNode.position();
            const updatedObj = { ...this.object };
            updatedObj.comment.x = x * this.scale;
            updatedObj.comment.y = y * this.scale;
            this.$emit('object-updated', updatedObj);
        },

        handleTextEdit() {
            try {
                if (this.isEditing) {
                    return;
                }

                this.isEditing = true;

                // hide text node and transformer:
                this.textNode.hide();

                // create textarea over canvas with absolute position
                // first we need to find position for textarea
                // how to find it?

                // at first lets find position of text node relative to the stage:
                const textPosition = this.textNode.absolutePosition();

                // so position of textarea will be the sum of positions above:
                const areaPosition = {
                    x: this.stage.container().offsetLeft + textPosition.x,
                    y: this.stage.container().offsetTop + textPosition.y,
                };

                this.handleCreateTextarea();
                // apply many styles to match text on canvas as close as possible
                // remember that text rendering on canvas and on the textarea can be different
                // and sometimes it is hard to make it 100% the same. But we will try...
                this.textarea.value = this.textNode.text();
                this.textarea.style.position = 'absolute';
                this.textarea.style.top = `${areaPosition.y - 1}px`;
                this.textarea.style.left = `${areaPosition.x}px`;
                this.textarea.style.width = `${200}px`;
                this.textarea.style.height = `${this.rectHeight}px`;// Set initial height
                this.textarea.style.fontSize = `${this.textNode.fontSize()}px`;
                this.textarea.style.border = 'none';
                this.textarea.style.margin = '0px';
                this.textarea.style.padding = '5px';
                this.textarea.style.overflow = 'auto';// Changed from 'hidden' to 'auto'
                this.textarea.style.background = 'none';
                this.textarea.style.outline = 'none';
                this.textarea.style.resize = 'none';
                this.textarea.style.fontFamily = this.textNode.fontFamily();
                this.textarea.style.transformOrigin = 'left top';
                this.textarea.style.textAlign = this.textNode.align();
                this.textarea.style.color = this.textNode.fill();
                this.textarea.style.zIndex = '99999';
                this.textarea.style.wordBreak = 'break-word';
                this.textarea.style.lineHeight = 1.5;
                this.textarea.style.fontWeight = 100;

                const rotation = this.textNode.rotation();
                let transform = '';
                if (rotation) {
                    transform += `rotateZ(${rotation}deg)`;
                }

                let px = 0;
                // also we need to slightly move textarea on firefox
                // because it jumps a bit
                const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
                if (isFirefox) {
                    px += 2 + Math.round(this.textNode.fontSize() / 20);
                }
                transform += `translateY(-${px}px)`;

                this.textarea.style.transform = transform;

                // Add event listener to auto-resize height
                this.textarea.addEventListener('input', (e) => {
                    const { target } = e;
                    const { value } = target;

                    this.handleUpdateText(value);
                });

                this.textarea.focus();

                this.textarea.addEventListener('keydown', (e) => {
                    e.stopPropagation();
                    // hide on enter
                    // but don't hide on shift + enter
                    if (e.key === 'Enter' && !e.shiftKey) {
                        this.handleUpdateText(this.textarea.value);
                        this.removeTextarea();
                        this.isEditing = false;
                    }
                    // on esc do not set value back to node
                    if (e.key === 'Escape') {
                        this.removeTextarea();
                        this.isEditing = false;
                    }
                });

                setTimeout(() => {
                    window.addEventListener('click', this.handleOutsideClick);
                });
            } catch (err) {
                console.error(err);
            }
        },

        handleOutsideClick(e) {
            if (this.textarea && this.textNode) {
                if (e.target !== this.textarea) {
                    this.textNode.text(this.textarea.value);
                    this.removeTextarea();
                    this.textarea = null;
                }
            }
        },

        setTextareaWidth(newWidth) {
            let width = newWidth;
            if (!width) {
                // set width for placeholder
                width = this.textNode.placeholder.length * this.textNode.fontSize();
            }
            // some extra fixes on different browsers
            const isSafari = /^((?!chrome|android).)*safari/i.test(
                navigator.userAgent,
            );
            const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            if (isSafari || isFirefox) {
                width = Math.ceil(width);
            }

            const isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
            if (isEdge) {
                width += 1;
            }

            this.textarea.style.width = `${width}px`;
        },

        removeTextarea() {
            if (this.textarea) {
                this.textarea.parentNode.removeChild(this.textarea);
                window.removeEventListener('click', this.handleOutsideClick);
                this.textNode.show();
            }
        },

        handleCreateTextarea() {
            const textarea = document.createElement('textarea');
            this.stage.container().appendChild(textarea);
            this.textarea = textarea;
        },

        handleClick(e) {
            const { target } = e;

            try {
                const editIcon = this.$refs[`edit-icon-${this.object.id}`].getNode();
                const iconButton = this.$refs[`icon-button-${this.object.id}`].getNode();

                switch (target) {
                    case iconButton:
                    case editIcon:
                        if (!this.isEditing) {
                            this.handleTextEdit();
                        } else {
                            if (this.textarea) {
                                this.handleUpdateText(this.textarea.value);
                                this.removeTextarea();
                            }
                            this.isEditing = false;
                        }
                        break;
                    default:
                        break;
                }
            } catch (err) {
                console.error(err);
            }
        },

        handleMouseOverGrab() {
            switch (this.mode) {
                case MODES.EDIT:
                    this.stage.container().style.cursor = 'grab';
                    break;
                case MODES.VIEW:
                default:
                    this.showComment = true;
                    this.stage.container().style.cursor = this.defaultCursor;
            }
        },

        handleMouseOutGrab() {
            this.stage.container().style.cursor = this.defaultCursor;
            this.showComment = false;
        },

        handleMouseOverButton(e) {
            e.cancelBubble = true;
            this.stage.container().style.cursor = 'pointer';
        },

        handleMouseOutButton(e) {
            e.cancelBubble = true;
            this.stage.container().style.cursor = this.defaultCursor;
        },

        handlePreventDrag(e) {
            e.cancelBubble = true;
            e.preventDefault();
        },

        updateRectHeight() {
            if (this.textNode) {
                const height = this.textNode.height();
                this.rectHeight = height;
                this.textarea.style.height = `${height}px`;
            }
        },

        handleForceStopEdit() {
            if (this.isEditing) {
                this.handleUpdateText(this.textarea.value);
                this.removeTextarea();
                this.isEditing = false;
            }
        },
    },

    computed: {
        rectNode() {
            return this.$refs[`comment-rect-${this.object.id}`].getNode();
        },

        textNode() {
            return this.$refs[`comment-text-${this.object.id}`].getNode();
        },

        rectWidth() {
            return this.showComment ? this.defaultRectWidth : (this.defaultRectWidth + 50);
        },

        dotConfig() {
            return {
                x: this.object.x / this.scale,
                y: this.object.y / this.scale,
                radius: 10,
                fill: '#f16b24ff',
                draggable: this.mode === 'edit',
                dragBoundFunc: (pos) => this.boundDrag(pos, {
                    width: 5, // Half of radius
                    height: 5, // Half of radius
                }),
            };
        },

        commentConfig() {
            return {
                x: this.object.comment.x / this.scale,
                y: this.object.comment.y / this.scale,
                width: this.rectWidth,
                height: this.rectHeight,
                fill: 'white',
                stroke: '#f16b24ff',
                strokeWidth: 1,
                draggable: this.isSelected,
                dragBoundFunc: (pos) => this.boundDrag(pos, {
                    width: this.rectWidth,
                    height: this.rectHeight,
                }),
                cornerRadius: 5,
            };
        },

        lineConfig() {
            return {
                points: this.connectionPoints,
                stroke: '#f16b24ff',
                strokeWidth: 3,
            };
        },

        textConfig() {
            return {
                x: this.commentConfig.x,
                y: this.commentConfig.y,
                text: this.object.comment.text,
                fontSize: 16,
                padding: 5,
                fill: 'black',
                width: this.defaultRectWidth,
                wrap: 'word',
                align: 'left',
                draggable: this.isSelected,
                lineHeight: 1.5,
                fontWeight: 100,
                dragBoundFunc: (pos) => this.boundDrag(pos, {
                    width: this.rectWidth,
                    height: this.rectHeight,
                }),
            };
        },

        /**
         * Icon config for icon at end of comment
         */
        iconConfig() {
            return {
                x: (this.commentConfig.x + (this.defaultRectWidth + 15)),
                y: (this.commentConfig.y + (this.rectHeight / 2) - 13),
                fill: 'black',
                scale: { x: 1, y: 1 },
                draggable: false,
            };
        },

        /**
         * Icon button config for button at end of comment
         */
        iconButtonConfig() {
            return {
                x: (this.commentConfig.x + (this.defaultRectWidth + 25)),
                y: this.submitIconConfig.y + 12,
                radius: 20,
                draggable: false,
            };
        },

        editIconConfig() {
            return {
                ...this.iconConfig,
                fill: 'black',
                data: 'M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z',
            };
        },

        submitIconConfig() {
            return {
                ...this.iconConfig,
                fill: 'green',
                data: 'M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z',
            };
        },

        connectionPoints() {
            return [
                this.dotConfig.x,
                this.dotConfig.y,
                (this.commentConfig.x + (this.rectWidth / 2)),
                (this.commentConfig.y + (this.rectHeight / 2)),
            ];
        },
    },

    watch: {
        showComment: {
            handler() {
                this.$nextTick(() => this.updateRectHeight());
            },
        },

        'object.comment.text': {
            handler(val) {
                // If val doesn't exist then its a fresh commment
                // so we set the text and put into edit mode on next tick
                if (val == null) {
                    this.handleUpdateText('');
                    this.$nextTick(() => this.handleTextEdit());
                    return;
                }

                this.$nextTick(() => this.updateRectHeight());
            },
            immediate: true,
        },

        isSelected: {
            handler(val) {
                // If unselected while editing then we need to save the updates
                if (!val) {
                    this.handleForceStopEdit();
                }
            },
            immediate: true,
        },

        mode: {
            handler() {
                this.handleForceStopEdit();
            },
        },

        tool: {
            handler() {
                this.handleForceStopEdit();
            },
        },
    },
};
</script>
