import { render, staticRenderFns } from "./LabelObj.vue?vue&type=template&id=1c8ec5b3&scoped=true"
import script from "./LabelObj.vue?vue&type=script&lang=js"
export * from "./LabelObj.vue?vue&type=script&lang=js"
import style0 from "./LabelObj.vue?vue&type=style&index=0&id=1c8ec5b3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c8ec5b3",
  null
  
)

export default component.exports