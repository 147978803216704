<template>
    <div v-if="visible" class="duration-dialog">
        <div class="dialog-content">
            <h3>Select Frame Range</h3>
            <label for="start-frame">Start Frame</label>
            <input
                id="start-frame"
                type="range"
                v-model="startFrame"
                :min="minFrame"
                :max="maxFrame"
                :step="1"
            />
            <label for="end-frame">End Frame</label>
            <input
                id="end-frame"
                type="range"
                v-model="endFrame"
                :min="minFrame"
                :max="maxFrame"
                :step="1"
            />
            <div class="frame-values">
                <span>Start Frame: {{ startFrame }}</span>
                <span>End Frame: {{ endFrame }}</span>
            </div>
            <div class="dialog-actions">
                <button type="button" @click="confirmSelection">Confirm</button>
                <button type="button" @click="closeDialog">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        frames: {
            type: Array,
            required: false,
            default: () => [],
        },
        minFrame: {
            type: Number,
            required: false,
            default: 0,
        },
        maxFrame: {
            type: Number,
            required: false,
            default: 0,
        },
        object: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            startFrame: this.minFrame,
            endFrame: this.maxFrame,
        };
    },
    methods: {
        confirmSelection() {
            this.$emit('update-range', this.object, { start: this.startFrame, end: this.endFrame });
        },
        closeDialog() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.duration-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.frame-values {
    margin: 10px 0;
}

.dialog-actions {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

button {
    padding: 5px 10px;
    cursor: pointer;
}
</style>
